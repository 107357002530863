export const replaceSpecialCharacters = (value?: string): string => {
  if (!value) {
    return '';
  }
  let character = value.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  if (character.includes('Đ') || character.includes('đ')) {
    character = character.replace(/Đ|đ/g, 'd');
  }
  // In some shops we need to delete the whitespaces between digits in the postal code (in BE it is set without whitespace)
  // but still we need to keep the white space between the characters to have a correct search for towns with more than one word
  return character.replace(/[^0-9a-zA-Z \x7f-\xff-]/g, '').replace(/(\d+)\s+(\d+)/g, '$1$2');
};
