import React, { FC } from 'react';
import classnames from 'classnames';
import { FeedbackCard } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import styles from 'components/LocationFinder/components/FeedbackAutoGeolocation/FeedbackAutoGeolocation.scss';

interface FeedbackAutoGeolocationProps {
  error: GeolocationPositionError;
}

type Variant = 'warning' | 'mute';

export const settings = {
  denied: {
    titleCode: 'wxs.availability.modal.map.location.disabled.headline',
    bodyCode: 'wxs.availability.modal.map.location.disabled.message',
    variant: 'quiet',
  },
  global: {
    titleCode: 'wxs.location.input.localization.error',
    bodyCode: 'wxs.location.input.manual.input',
    variant: 'warning',
  },
};

const FeedbackAutoGeolocation: FC<FeedbackAutoGeolocationProps> = ({ error }) => {
  const isPermissionDenied = error.code === error.PERMISSION_DENIED;
  const { titleCode, bodyCode, variant } = isPermissionDenied ? settings.denied : settings.global;
  const [title, body] = useMessage([titleCode, bodyCode]);

  return (
    <FeedbackCard
      className={classnames(styles.wrapper, { [styles.permissionDenied]: isPermissionDenied })}
      dismissible
      layout="block"
      variant={variant as Variant}
      title={title}
    >
      {body}
    </FeedbackCard>
  );
};

export default FeedbackAutoGeolocation;
