import React, { createContext, ReactNode, useContext } from 'react';
import { noop } from '@xxxlgroup/hydra-utils/common';
import { UseStateInterface } from 'components/LocationFinder/LocationFinder.types';
import { initialState } from 'components/LocationFinder/LocationFinder.state';

export const defaultContextValue: UseStateInterface = {
  state: initialState,
  stateSetters: {
    resetSuggestionsList: noop,
    setActiveListItemDescendant: noop,
    setActiveListItemIndexAndDescendant: noop,
    setActiveListItemIndex: noop,
    setAreSuggestionsShown: noop,
    setAriaLiveMessage: noop,
    setErrorMessageCode: noop,
    setErrorUserGeolocation: noop,
    setSearchTermAndResetError: noop,
    setSearchTermAndResetSuggestions: noop,
    setSuggestionsAndShowList: noop,
  },
};

const LocationFinderContext = createContext<UseStateInterface>(defaultContextValue);

export const useLocationFinderContext = () => useContext(LocationFinderContext);
export default LocationFinderContext;

// used in tests
export const withLocationFinderContext = (component: ReactNode, value: UseStateInterface) => (
  <LocationFinderContext.Provider value={value}>{component}</LocationFinderContext.Provider>
);
