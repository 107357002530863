import { useCallback, useEffect, useState, useMemo } from 'react';

export interface GeolocationOptions {
  maximumAge?: number;
  timeout?: number;
  enableHighAccuracy?: boolean;
}

export interface UseCurrentPositionReturn {
  position?: GeolocationPosition;
  error?: GeolocationPositionError;
  isLoading?: boolean;
  loadCurrentPosition: () => void;
}

const useCurrentPosition = (
  loadCurrentPositionByDefault = true,
  options: GeolocationOptions = {},
): UseCurrentPositionReturn => {
  const [position, setPosition] = useState<GeolocationPosition>();
  const [error, setError] = useState<GeolocationPositionError>();
  const [isLoading, setIsLoading] = useState(false);

  const memoOptions = useMemo(
    () => options,
    // eslint-disable-next-line
    [JSON.stringify(options)],
  );

  const loadCurrentPosition = useCallback(() => {
    let canceled = false;
    setIsLoading(true);

    if (navigator?.geolocation) {
      const onSuccess = (newPosition: GeolocationPosition) => {
        if (!canceled) {
          setPosition(newPosition);
          setIsLoading(false);
        }
      };

      const onError = (newError: GeolocationPositionError) => {
        if (!canceled) {
          setError(newError);
          setIsLoading(false);
        }
      };
      navigator.geolocation.getCurrentPosition(onSuccess, onError, memoOptions);
    }

    return () => {
      canceled = true;
    };
  }, [memoOptions]);

  useEffect(() => {
    if (loadCurrentPositionByDefault) {
      return loadCurrentPosition();
    }

    return () => undefined;
  }, [loadCurrentPositionByDefault, memoOptions, loadCurrentPosition]);

  return { position, error, loadCurrentPosition, isLoading };
};

export default useCurrentPosition;
