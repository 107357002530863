import React, { FC, FocusEvent, useRef, useMemo, useCallback, useEffect } from 'react';
import classnames from 'classnames';
import track from 'react-tracking';
import { useLazyQuery } from '@apollo/client';
import { Input } from '@xxxlgroup/hydra-ui-components';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import { tagComponent } from 'utils/tracking/tracking';
import { useTracking } from 'utils/tracking/hooks';
import { debouncing } from 'utils/function';
import { DEBOUNCING_TIME_SHORT } from 'constantsDefinitions';
import {
  ARIA_LIST_ID,
  ERROR_CODES,
  LocationFinderProps,
  LocationSourcesFromStorage,
  NavigationKeys,
} from 'components/LocationFinder/LocationFinder.types';
import { LocationStorageSource } from 'components/SubsidiaryModal/types';
import useMessage from 'components/Message/useMessage';
import useMediaQuery from 'components/MediaQuery/useMediaQuery';
import ListSuggestions from 'components/LocationFinder/components/ListSuggestions';
import LocationFinderContext from 'components/LocationFinder/LocationFinder.context';
import {
  SUGGEST_ZIPCODE_QUERY,
  ZIPCODE_QUERY,
} from 'pages/ProductDetail/components/ProductAvailability/ProductAvailability.query';
import updateSearchTerm from 'components/LocationFinder/utils/updateSearchTerm';
import useComputeIndex from 'components/LocationFinder/utils/useComputeIndex';
import { replaceSpecialCharacters } from 'utils/normalize';
import useCurrentUserLocation from 'components/LocationFinder/utils/useCurrentUserLocation';
import styles from 'components/LocationFinder/LocationFinder.scss';
import useLocationFinderState from 'components/LocationFinder/LocationFinder.state';
import FeedbackAutoGeolocation from 'components/LocationFinder/components/FeedbackAutoGeolocation';
import { getProductOnLocationForCart } from 'components/Entries/utils/localStorageUserLocation';
import Message from 'components/Message';

const LocationFinder: FC<LocationFinderProps> = (props) => {
  const {
    className = '',
    classNameInput = '',
    hasAutofocus = false,
    inputErrorCode = '',
    onChangeInput = noop,
    onSubmitZipCode,
    locationSourceFromStorage,
    locationStorageSource,
    productCode = '',
  } = props;

  const tracking = useTracking(props, 'LocationSearch');
  const {
    state,
    stateSetters,
    state: {
      errorUserGeolocation,
      errorMessageCode,
      searchTerm,
      suggestions,
      activeListItemIndex,
      areSuggestionsShown,
      activeListItemDescendant,
      ariaLiveMessage,
    },
    stateSetters: {
      resetSuggestionsList,
      setAreSuggestionsShown,
      setActiveListItemIndex,
      setErrorMessageCode,
      setErrorUserGeolocation,
      setSearchTermAndResetError,
      setSuggestionsAndShowList,
      setSearchTermAndResetSuggestions,
      setAriaLiveMessage,
    },
  } = useLocationFinderState();

  const inputRef = useRef<HTMLInputElement>(null);
  const isTouch = useMediaQuery({ touchOnly: true });
  const computeIndex = useComputeIndex(state, stateSetters);
  const errorMessage = useMessage(errorMessageCode || inputErrorCode, {}, true);
  const [labelInput, placeholderMessage, cancelMessage] = useMessage(
    [
      'consultation.search.zipOrCity.label',
      'consultation.search.zipOrCity.placeholder',
      'wxs.input.cancel',
    ],
    undefined,
    true,
  );

  // Will only be fired, if no zip-code suggestions are available when user is submitting the input value
  const [getZipCode] = useLazyQuery(ZIPCODE_QUERY, {
    onCompleted: ({ getZipCode: getZipCodeData }) => {
      // this is needed because cached requests are handled as successful
      if (!getZipCodeData) {
        return setErrorMessageCode(ERROR_CODES.NOT_FOUND);
      }

      const { zipCode } = getZipCodeData;
      const zipLabel = zipCode?.label;
      const zipCodeValue = zipCode?.value;

      if (searchTerm !== zipLabel) {
        setSearchTermAndResetError(zipLabel);
      }

      resetSuggestionsList();

      return onSubmitZipCode({
        zipCode: zipCodeValue,
        zipLabel,
        // Passed, for cases, where the search values need to be updated for more location searches (cart listing)
        updateSearchTerm: updateSearchTerm(setSearchTermAndResetError),
        setAriaLiveMessage,
      });
    },
    onError: ({ graphQLErrors }) => {
      if (graphQLErrors.length) {
        const { extensions: { statusCode } = {} } = graphQLErrors[0];
        const messageCode = statusCode === 400 ? ERROR_CODES.NOT_FOUND : ERROR_CODES.GENERAL;
        return setErrorMessageCode(messageCode);
      }
      return null;
    },
    fetchPolicy: 'no-cache',
    notifyOnNetworkStatusChange: true,
    errorPolicy: 'none',
  });

  const [getSuggestions] = useLazyQuery(SUGGEST_ZIPCODE_QUERY, {
    onCompleted: ({ suggestZipCode: { postalcodes } }) => {
      setSuggestionsAndShowList(postalcodes);
    },
    // caching is disabled due to the apollo cache lazyQuery Observable bug
    // -> when requesting the same searchTerm twice, the observable doesn't return the data
    // and thus doesn't trigger `onCompleted` or a rerender
    fetchPolicy: 'network-only',
  });

  const submitZipCode = useCallback(
    (zipCode: string, zipLabel: string) => {
      onSubmitZipCode({
        zipCode,
        zipLabel,
        updateSearchTerm: updateSearchTerm(setSearchTermAndResetError),
        setAriaLiveMessage,
      });

      setSearchTermAndResetSuggestions(zipLabel);
    },
    [
      onSubmitZipCode,
      setAriaLiveMessage,
      setSearchTermAndResetError,
      setSearchTermAndResetSuggestions,
    ],
  );

  useEffect(() => {
    if (locationSourceFromStorage) {
      const storedLocation =
        locationStorageSource === LocationStorageSource.LOCATION_FOR_CART
          ? getProductOnLocationForCart(productCode)
          : local.getItem(LocationStorageSource.LOCATION);

      const isUserLocation = locationSourceFromStorage === LocationSourcesFromStorage.USER_LOCATION;

      if (storedLocation) {
        const { userLocation, zipCode, zipLabel } = storedLocation;
        const newSearchTerm = isUserLocation && userLocation ? userLocation.zipLabel : zipLabel;

        onSubmitZipCode({
          userLocation: isUserLocation ? userLocation : null,
          zipCode,
          zipLabel,
          updateSearchTerm: updateSearchTerm(setSearchTermAndResetError),
          setAriaLiveMessage,
        });

        setSearchTermAndResetError(newSearchTerm);
      }
    }
  }, [
    locationSourceFromStorage,
    locationStorageSource,
    onSubmitZipCode,
    productCode,
    setAriaLiveMessage,
    setSearchTermAndResetError,
  ]);

  const onSelectListItem = useCallback(
    (selectedIndex: number) => {
      const { value, label: selectedLabel } = suggestions[selectedIndex];
      submitZipCode(value || '', selectedLabel || '');
    },
    [submitZipCode, suggestions],
  );

  const handleSuggestionAppearance = useCallback(
    (value: boolean) => (event?: FocusEvent) => {
      tracking(event);

      if (!value) {
        setAreSuggestionsShown(false);
      } else if (!isArrayEmpty(suggestions)) {
        setAreSuggestionsShown(true);
      }
    },
    [setAreSuggestionsShown, suggestions, tracking],
  );

  const getSuggestionsDebounced = debouncing((zipCode: string) => {
    getSuggestions({ variables: { search: zipCode } });
  }, DEBOUNCING_TIME_SHORT);

  const handleInputChange = (event: Event, trimmedValue: string) => {
    tracking(event);
    const normalizedValue = replaceSpecialCharacters(trimmedValue);

    setAriaLiveMessage('');
    setSearchTermAndResetError(normalizedValue);
    onChangeInput(normalizedValue);

    if (normalizedValue) {
      getSuggestionsDebounced(normalizedValue);
    } else if (!normalizedValue && areSuggestionsShown) {
      resetSuggestionsList();
    }
  };

  const handleEnterKey = useCallback(
    async (event: Event) => {
      if (!areSuggestionsShown) {
        await getSuggestions({ variables: { search: searchTerm } });
      }

      const index = activeListItemIndex > -1 ? activeListItemIndex : 0;

      if (isArrayEmpty(suggestions)) {
        getZipCode({ variables: { search: searchTerm } });
      } else {
        const { value, label: selectedLabel } = suggestions[index];
        submitZipCode(value || '', selectedLabel || '');
      }

      const storedLocation =
        locationStorageSource === LocationStorageSource.LOCATION_FOR_CART
          ? getProductOnLocationForCart(productCode)
          : local.getItem(LocationStorageSource.LOCATION);

      tracking(event, {
        event: { ...event, purpose: 'locationSearch.input.field' },
        props: {
          searchTerm,
          selectedLabel: suggestions[index]?.label ?? '',
          store_id: storedLocation?.pickupStationCode,
        },
        type: 'LocationSearchInput',
      });
    },
    [
      activeListItemIndex,
      areSuggestionsShown,
      getSuggestions,
      getZipCode,
      locationStorageSource,
      productCode,
      searchTerm,
      submitZipCode,
      suggestions,
      tracking,
    ],
  );

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      const key = event.key.toLowerCase();

      switch (key) {
        case NavigationKeys.ENTER:
          handleEnterKey(event);
          break;
        case NavigationKeys.ESCAPE:
          event.preventDefault();
          resetSuggestionsList();
          break;
        case NavigationKeys.ARROW_UP:
        case NavigationKeys.ARROW_DOWN:
          event.preventDefault();
          computeIndex(key);
          break;
        default:
          // unselect suggestion when the user continue typing in the input in case there is a selected suggestion
          if (activeListItemIndex > -1) {
            setActiveListItemIndex(-1);
          }
          break;
      }
    },
    [
      activeListItemIndex,
      computeIndex,
      handleEnterKey,
      resetSuggestionsList,
      setActiveListItemIndex,
    ],
  );

  useEffect(() => {
    const input = inputRef.current;

    if (input) {
      input.addEventListener(NavigationKeys.KEY_DOWN, handleKeyDown);

      return () => {
        input.removeEventListener(NavigationKeys.KEY_DOWN, handleKeyDown);
      };
    }

    return undefined;
  }, [areSuggestionsShown, handleKeyDown]);

  const userLocationButton = useCurrentUserLocation(getZipCode, setErrorUserGeolocation);

  const ariaAttributes = {
    'aria-expanded': `${areSuggestionsShown}`,
    'aria-autocomplete': 'both',
    'aria-activedescendant': activeListItemDescendant,
    'aria-label': placeholderMessage,
    'aria-owns': areSuggestionsShown ? ARIA_LIST_ID : undefined,
    autoFocus: hasAutofocus,
  };

  const i18nInput = useMemo(
    () => ({
      cancel: cancelMessage,
    }),
    [cancelMessage],
  );

  const context = useMemo(
    () => ({
      state,
      stateSetters,
    }),
    [state, stateSetters],
  );

  return (
    <LocationFinderContext.Provider value={context}>
      <div className={styles.screenReader} role="status" aria-live="polite">
        {ariaLiveMessage && (
          <Message
            code={ariaLiveMessage.code}
            values={ariaLiveMessage.values}
            isInlineEditDisabled
          />
        )}
      </div>
      <div className={classnames(styles.wrapper, className)}>
        <Input
          actionButton={userLocationButton}
          autoComplete="off"
          {...ariaAttributes}
          className={classNameInput}
          role="combobox"
          data-purpose="locationSearch.input"
          errors={errorMessage}
          i18n={i18nInput}
          isTouch={isTouch}
          label={labelInput}
          name="zip"
          hideLabel={false}
          forwardedRef={inputRef}
          onBlur={handleSuggestionAppearance(false)}
          onChange={handleInputChange}
          onFocus={handleSuggestionAppearance(true)}
          placeholder={placeholderMessage}
          // an <input /> cannot have both undefined and/or string values in the same lifetime cycle: https://react.dev/reference/react-dom/components/input#controlling-an-input-with-a-state-variable
          value={searchTerm ?? ''}
        />
        {areSuggestionsShown && <ListSuggestions onSelectListItem={onSelectListItem} />}
      </div>
      {errorUserGeolocation && <FeedbackAutoGeolocation error={errorUserGeolocation} />}
    </LocationFinderContext.Provider>
  );
};

export default track(tagComponent('LocationSearch'))(LocationFinder);
