import {
  LIST_ITEM_ID_PREFIX,
  NavigationKeys,
  State,
  StateSetters,
} from 'components/LocationFinder/LocationFinder.types';
import { useCallback } from 'react';

// This hook is computing the index for the active list item from the zip-suggestion and setting it on the global component state
const useComputeIndex = (state: State, stateSetters: StateSetters) => {
  const { activeListItemIndex, suggestions } = state;
  const { setActiveListItemIndexAndDescendant } = stateSetters;

  return useCallback(
    (key: NavigationKeys) => {
      const newIndex = activeListItemIndex + (key === NavigationKeys.ARROW_DOWN ? 1 : -1);

      // This makes sure the index stays in the boundaries of the suggestions (0 < index < suggestions.length).
      // It enables the user to "loop" through the suggestions
      // (if you are at the last suggestion and still press "arrow down", you will be at the top of the suggestions again)
      const modulo = newIndex % suggestions.length;
      const index = modulo < 0 ? suggestions.length + modulo : modulo;

      setActiveListItemIndexAndDescendant(index, `${LIST_ITEM_ID_PREFIX}${index}`);
    },
    [activeListItemIndex, setActiveListItemIndexAndDescendant, suggestions.length],
  );
};

export default useComputeIndex;
