import { Reducer, useCallback, useMemo, useReducer } from 'react';
import { PostalCode } from 'graphql-types/generated/types-generated';
import {
  Action,
  Actions,
  AriaLiveMessage,
  State,
  UseStateInterface,
} from 'components/LocationFinder/LocationFinder.types';

export const initialState: State = {
  ariaLiveMessage: null,
  searchTerm: '',
  suggestions: [],
  areSuggestionsShown: false,
  activeListItemDescendant: '',
  activeListItemIndex: -1,
  errorMessageCode: '',
  errorUserGeolocation: null,
};

export const reducer = (state: State, action: Action): State => {
  const { type, payload } = action;

  switch (type) {
    case Actions.RESET_SUGGESTIONS_LIST:
      return {
        ...state,
        areSuggestionsShown: false,
        activeListItemDescendant: '',
        suggestions: [],
      };
    case Actions.SET_ACTIVE_LIST_ITEM_INDEX_AND_DESCENDANT:
      return {
        ...state,
        activeListItemIndex: payload.index,
        activeListItemDescendant: payload.descendant,
      };
    case Actions.SET_ACTIVE_LIST_ITEM_DESCENDANT:
      return {
        ...state,
        activeListItemDescendant: payload,
      };
    case Actions.SET_ACTIVE_LIST_ITEM_INDEX:
      return {
        ...state,
        activeListItemIndex: payload,
      };
    case Actions.SET_ARE_SUGGESTIONS_SHOWN:
      return {
        ...state,
        areSuggestionsShown: payload,
      };
    case Actions.SET_ARIA_LIVE_MESSAGE:
      return {
        ...state,
        ariaLiveMessage: payload.code
          ? {
              code: payload.code,
              values: payload.values,
            }
          : null,
      };
    case Actions.SET_ERROR_MESSAGE_CODE:
      return {
        ...state,
        errorMessageCode: payload,
      };
    case Actions.SET_ERROR_USER_GEOLOCATION:
      return {
        ...state,
        errorUserGeolocation: payload,
      };
    case Actions.SET_SEARCH_TERM_AND_RESET_ERROR:
      return {
        ...state,
        searchTerm: payload,
        errorMessageCode: '',
        errorUserGeolocation: null,
      };
    case Actions.SET_SEARCH_TERM_AND_RESET_SUGGESTIONS:
      return {
        ...state,
        searchTerm: payload,
        errorMessageCode: '',
        suggestions: [],
        areSuggestionsShown: false,
      };
    case Actions.SET_SUGGESTIONS_AND_SHOW_LIST:
      return {
        ...state,
        suggestions: payload,
        areSuggestionsShown: true,
      };

    default:
      throw new Error(`Action ${type} not found in LocationSearch.`);
  }
};

const useLocationFinderState = (): UseStateInterface => {
  const [state, dispatch] = useReducer<Reducer<State, Action>>(reducer, initialState);

  const resetSuggestionsList = useCallback(
    () => dispatch({ type: Actions.RESET_SUGGESTIONS_LIST }),
    [],
  );

  const setActiveListItemDescendant = useCallback(
    (activeDescendant: string) =>
      dispatch({ type: Actions.SET_ACTIVE_LIST_ITEM_DESCENDANT, payload: activeDescendant }),
    [],
  );

  const setActiveListItemIndex = useCallback(
    (activeIndex: number) =>
      dispatch({ type: Actions.SET_ACTIVE_LIST_ITEM_INDEX, payload: activeIndex }),
    [],
  );

  const setActiveListItemIndexAndDescendant = useCallback(
    (index: number, descendant: string) =>
      dispatch({
        type: Actions.SET_ACTIVE_LIST_ITEM_INDEX_AND_DESCENDANT,
        payload: { index, descendant },
      }),
    [],
  );

  const setAreSuggestionsShown = useCallback(
    (areSuggestionsShown: boolean) =>
      dispatch({ type: Actions.SET_ARE_SUGGESTIONS_SHOWN, payload: areSuggestionsShown }),
    [],
  );

  const setAriaLiveMessage = useCallback(
    (code: AriaLiveMessage['code'], values?: AriaLiveMessage['values']) =>
      dispatch({ type: Actions.SET_ARIA_LIVE_MESSAGE, payload: { code, values } }),
    [],
  );

  const setErrorMessageCode = useCallback(
    (code: string) => dispatch({ type: Actions.SET_ERROR_MESSAGE_CODE, payload: code }),
    [],
  );

  const setErrorUserGeolocation = useCallback((error: GeolocationPositionError | null) => {
    dispatch({ type: Actions.SET_ERROR_USER_GEOLOCATION, payload: error });
  }, []);

  const setSearchTermAndResetError = useCallback(
    (searchTerm: string) =>
      dispatch({ type: Actions.SET_SEARCH_TERM_AND_RESET_ERROR, payload: searchTerm }),
    [],
  );

  const setSearchTermAndResetSuggestions = useCallback(
    (searchTerm: string) =>
      dispatch({ type: Actions.SET_SEARCH_TERM_AND_RESET_SUGGESTIONS, payload: searchTerm }),
    [],
  );

  const setSuggestionsAndShowList = useCallback(
    (suggestions: PostalCode[]) =>
      dispatch({ type: Actions.SET_SUGGESTIONS_AND_SHOW_LIST, payload: suggestions }),
    [],
  );

  return useMemo(
    () => ({
      state,
      stateSetters: {
        resetSuggestionsList,
        setActiveListItemDescendant,
        setAreSuggestionsShown,
        setAriaLiveMessage,
        setErrorMessageCode,
        setErrorUserGeolocation,
        setSearchTermAndResetError,
        setSearchTermAndResetSuggestions,
        setSuggestionsAndShowList,
        setActiveListItemIndex,
        setActiveListItemIndexAndDescendant,
      },
    }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      resetSuggestionsList,
      setActiveListItemDescendant,
      setAreSuggestionsShown,
      setAriaLiveMessage,
      setErrorMessageCode,
      setSearchTermAndResetError,
      setSearchTermAndResetSuggestions,
      setSuggestionsAndShowList,
      setActiveListItemIndexAndDescendant,
      // eslint-disable-next-line react-hooks/exhaustive-deps
      JSON.stringify(state),
    ],
  );
};

export default useLocationFinderState;
