import React, { FC, FocusEvent, MouseEvent, useCallback } from 'react';
import classnames from 'classnames';
import { isArrayEmpty, noop } from '@xxxlgroup/hydra-utils/common';
import { local } from '@xxxlgroup/hydra-utils/storage';
import { pseudoIcon } from '@xxxlgroup/hydra-utils/icon';
import { arrowUp } from '@xxxlgroup/hydra-icons';
import { PostalCode } from 'graphql-types/generated/types-generated';
import { useTracking } from 'utils/tracking/hooks';
import {
  ARIA_LIST_ID,
  LIST_ITEM_ID_PREFIX,
  ListSuggestionsProps,
} from 'components/LocationFinder/LocationFinder.types';
import { useLocationFinderContext } from 'components/LocationFinder/LocationFinder.context';
import styles from 'components/LocationFinder/components/ListSuggestions/ListSuggestions.scss';

const ListZipCodeSuggestions: FC<ListSuggestionsProps> = (props) => {
  const tracking = useTracking(props, 'LocationSuggestions');
  const [iconStyle, iconClassName] = pseudoIcon(arrowUp, 'after');
  const { onSelectListItem = noop } = props;
  const {
    state: { suggestions, searchTerm, activeListItemIndex },
    stateSetters: { setActiveListItemIndexAndDescendant },
  } = useLocationFinderContext();
  const { pickupStationCode } = local.getItem('location') ?? {};

  const handleSubmitSuggestion = useCallback(
    (selectedIndex: number) => (event: MouseEvent<HTMLButtonElement> | KeyboardEvent) => {
      const index = selectedIndex || (activeListItemIndex > -1 ? activeListItemIndex : 0);
      tracking(event, {
        context: [],
        props: {
          searchTerm,
          zipCode: suggestions[index]?.value,
          store_id: pickupStationCode,
        },
        type: 'SuggestionListButton',
      });
      onSelectListItem(index);
    },
    [activeListItemIndex, onSelectListItem, searchTerm, pickupStationCode, suggestions, tracking],
  );

  const handleMouseOver = useCallback(
    (index: number) => (event: MouseEvent<HTMLButtonElement> | FocusEvent<HTMLButtonElement>) => {
      tracking(event, {
        context: [],
        props: {
          searchTerm,
          zipCode: suggestions[index]?.value,
          store_id: pickupStationCode,
        },
        type: 'SuggestionListButton',
      });

      setActiveListItemIndexAndDescendant(index, `${LIST_ITEM_ID_PREFIX}${index}`);
    },
    [searchTerm, setActiveListItemIndexAndDescendant, pickupStationCode, suggestions, tracking],
  );

  const renderSuggestion = (location: PostalCode, index: number) => {
    const { value, label } = location;

    return (
      <li
        key={value}
        id={`${LIST_ITEM_ID_PREFIX}${index}`}
        role="option"
        aria-selected={index === activeListItemIndex}
      >
        <button
          data-purpose="suggestion.button"
          data-zipcode={value}
          className={classnames(styles.button, iconClassName, {
            [styles.active]: index === activeListItemIndex,
          })}
          onMouseDown={handleSubmitSuggestion(index)}
          onMouseOver={handleMouseOver(index)}
          onFocus={handleMouseOver(index)}
          style={iconStyle}
        >
          <span>{label}</span>
        </button>
      </li>
    );
  };

  if (isArrayEmpty(suggestions)) {
    return null;
  }

  return (
    <ul role="listbox" id={ARIA_LIST_ID} className={classnames(styles.list)}>
      {suggestions.map(renderSuggestion)}
    </ul>
  );
};

export default ListZipCodeSuggestions;
