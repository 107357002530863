import { FocusEvent } from 'react';
import { PostalCode } from 'graphql-types/generated/types-generated';
import { LocationStorageSource } from 'components/SubsidiaryModal/types';

export const LIST_ITEM_ID_PREFIX = 'zipcode_';
export const ARIA_LIST_ID = `${LIST_ITEM_ID_PREFIX}listbox`;

export enum ERROR_CODES {
  NOT_FOUND = 'wxs.availability.error.notFound',
  GENERAL = 'wxs.error.headline',
}

export enum LocationSourcesFromStorage {
  USER_LOCATION = 'userLocation',
  SELF_SERVICE = 'selfService',
}

export enum Actions {
  SET_ACTIVE_LIST_ITEM_INDEX = 'SET_ACTIVE_LIST_ITEM_INDEX',
  SET_ACTIVE_LIST_ITEM_DESCENDANT = 'SET_ACTIVE_LIST_ITEM_DESCENDANT',
  SET_ACTIVE_LIST_ITEM_INDEX_AND_DESCENDANT = 'SET_ACTIVE_LIST_ITEM_INDEX_AND_DESCENDANT',
  SET_ARIA_LIVE_MESSAGE = 'SET_ARIA_LIVE_MESSAGE',
  SET_ERROR_MESSAGE_CODE = 'SET_ERROR_MESSAGE_CODE',
  SET_ERROR_USER_GEOLOCATION = 'SET_ERROR_USER_GEOLOCATION',
  SET_SEARCH_TERM_AND_RESET_ERROR = 'SET_SEARCH_TERM_AND_RESET_ERROR',
  SET_ARE_SUGGESTIONS_SHOWN = 'SET_ARE_SUGGESTIONS_SHOWN',
  SET_SUGGESTIONS_AND_SHOW_LIST = 'SET_SUGGESTIONS_AND_SHOW_LIST',
  SET_SEARCH_TERM_AND_RESET_SUGGESTIONS = 'SET_SEARCH_TERM_AND_RESET_SUGGESTIONS',
  RESET_SUGGESTIONS_LIST = 'RESET_SUGGESTIONS_LIST',
}

export interface AriaLiveMessage {
  code: string | string[];
  values?: Record<string, string | number>;
}
export type SetAriaLiveMessage = (
  code: AriaLiveMessage['code'],
  values?: AriaLiveMessage['values'],
) => void;

export interface State {
  ariaLiveMessage: AriaLiveMessage | null;
  searchTerm: string;
  suggestions: PostalCode[];
  areSuggestionsShown: boolean;
  activeListItemDescendant: string;
  activeListItemIndex: number;
  errorMessageCode: string;
  errorUserGeolocation: GeolocationPositionError | null;
}

export interface StateSetters {
  resetSuggestionsList: () => void;
  setActiveListItemDescendant: (activeDescendant: string) => void;
  setActiveListItemIndexAndDescendant: (index: number, descendant: string) => void;
  setActiveListItemIndex: (activeIndex: number) => void;
  setAreSuggestionsShown: (areSuggestionsShown: boolean) => void;
  setAriaLiveMessage: SetAriaLiveMessage;
  setErrorMessageCode: (code: string) => void;
  setErrorUserGeolocation: (type: GeolocationPositionError | null) => void;
  setSearchTermAndResetError: (searchTerm: string) => void;
  setSearchTermAndResetSuggestions: (searchTerm: string) => void;
  setSuggestionsAndShowList: (suggestions: PostalCode[]) => void;
}

export interface UseStateInterface {
  state: State;
  stateSetters: StateSetters;
}

export interface Action {
  type: Actions;
  payload?: any;
}

export interface UserLocation {
  zipCode: string;
  zipLabel: string;
}

export type OnSubmitZipCode = ({
  zipCode,
  zipLabel,
  updateSearchTerm,
  userLocation,
  setAriaLiveMessage,
}: {
  zipCode: string;
  zipLabel?: string;
  updateSearchTerm?: (updatedZipCode: string) => void;
  userLocation?: UserLocation;
  setAriaLiveMessage?: SetAriaLiveMessage;
}) => void;

export interface LocationFinderProps {
  className?: string;
  classNameInput?: string;
  hasAutofocus?: boolean;
  inputErrorCode?: string;
  locationSourceFromStorage: LocationSourcesFromStorage | null;
  locationStorageSource?: LocationStorageSource;
  onChangeInput?: (value: string) => void;
  onSubmitZipCode: OnSubmitZipCode;
  productCode?: string;
}

export enum NavigationKeys {
  ARROW_UP = 'arrowup',
  ARROW_DOWN = 'arrowdown',
  ESCAPE = 'escape',
  ENTER = 'enter',
  KEY_DOWN = 'keydown',
}

export interface ListSuggestionsProps {
  /** reference to the input field for handling keyboard interactions */
  inputElement?: HTMLInputElement | null;
  /** callback function when suggestions will be closed */
  onCloseSuggestions?: (event?: FocusEvent) => void;
  /** callback that handles the selection of a suggestion (click or keyboard) */
  onSelectListItem?: (suggestionIndex: number) => void;
}
