import { local } from '@xxxlgroup/hydra-utils/storage';
import type { LocationForCart } from 'components/SubsidiaryModal/utils/setPointOfServiceOnLocal';

/**
 * Getting a prop from the user location data from the local storage, or all the location data from there
 */

export const getLocalUserLocation = (prop = '') =>
  (prop ? (local.getItem('location') || {})[prop] : local.getItem('location')) || {};

/**
 * Setting the user location data in the local storage
 */
export const setLocalUserLocation = (prop: string) => local.setItem('location', prop);

/**
 * Getting an object with entry data for location management in cart from the local storage
 */
export const getProductOnLocationForCart = (productCode: string): null | LocationForCart =>
  local
    .getItem('locationForCart')
    ?.find((location: LocationForCart) => location.productCode === productCode);
