import React, { ReactElement, useEffect, useCallback } from 'react';
import { useLazyQuery } from '@apollo/client';
import { locate } from '@xxxlgroup/hydra-icons';
import { IconButton, Loading } from '@xxxlgroup/hydra-ui-components';
import useMessage from 'components/Message/useMessage';
import { ZIPCODE_QUERY } from 'pages/ProductDetail/components/ProductAvailability/ProductAvailability.query';
import useCurrentPosition from 'modules/libs/geo-service/useCurrentPosition';
import { GetZipCodeQueryVariables } from 'graphql-types/generated/types-generated';
import styles from 'components/LocationFinder/LocationFinder.scss';

interface ZipCodeParams {
  variables: GetZipCodeQueryVariables;
}

const useCurrentUserLocation = (
  getZipCode: (params: ZipCodeParams) => void,
  setErrorUserGeolocation: (type: GeolocationPositionError | null) => void,
): ReactElement => {
  const {
    position,
    loadCurrentPosition,
    isLoading: isLoadingCurrentPosition,
    error,
  } = useCurrentPosition(false);

  const [getZipFromPosition, { loading: isLoadingZipQuery }] = useLazyQuery(ZIPCODE_QUERY, {
    onCompleted: ({ getZipCode: getZipCodeData }) => {
      const { value } = getZipCodeData.zipCode;
      getZipCode({ variables: { search: value } });
    },
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (position) {
      getZipFromPosition({
        variables: {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        },
      });
    }
  }, [position, getZipFromPosition]);

  useEffect(() => {
    if (error) {
      setErrorUserGeolocation(error);
    }
  }, [error, setErrorUserGeolocation]);

  const handleLocate = useCallback(() => {
    setErrorUserGeolocation(null);
    loadCurrentPosition();
  }, [loadCurrentPosition, setErrorUserGeolocation]);

  const buttonAriaLabel = useMessage(
    'accessibility.locationSearch.getCurrentLocation',
    undefined,
    true,
  );

  if (isLoadingZipQuery || isLoadingCurrentPosition) {
    return <Loading type="dots" className={styles.locatorLoading} />;
  }

  return (
    <IconButton
      ariaLabel={buttonAriaLabel}
      className={styles.locatorButton}
      data-purpose="locate.button"
      glyph={locate}
      onClick={handleLocate}
    />
  );
};

export default useCurrentUserLocation;
