import { gql } from '@apollo/client';
import { subsidiary } from 'graphql-fragments/fragments';

export const AVAILABILITY_QUERY = gql`
  ${subsidiary}
  query productAvailability(
    $productCode: String!
    $qty: String
    $zipCode: String
    $maxSubsidiariesShown: String
  ) {
    getProductAvailability(
      productCode: $productCode
      qty: $qty
      zipCode: $zipCode
      maxSubsidiariesShown: $maxSubsidiariesShown
    ) {
      assembly
      nearestAvailableSubsidiaries {
        reservationSubsidiaries {
          ...subsidiary
        }
        selfServiceSubsidiaries {
          ...subsidiary
        }
      }
      status
      shippingInformation {
        deliveryTime
        deliveryTimeText
        shippingType
      }
      subsidiaries {
        ...subsidiary
      }
    }
  }
`;

export const ZIPCODE_QUERY = gql`
  query getZipCode($search: String, $lat: Float, $lng: Float) {
    getZipCode(search: $search, lat: $lat, lng: $lng) {
      zipCode {
        id
        label
        value
      }
      geometry {
        location {
          lat
          lng
        }
        viewport {
          northeast {
            lat
            lng
          }
          southwest {
            lat
            lng
          }
        }
      }
    }
  }
`;

export const SUGGEST_ZIPCODE_QUERY = gql`
  query zipCode($search: String!) {
    suggestZipCode(search: $search) {
      postalcodes {
        value
        town
        label
      }
    }
  }
`;
