import {
  PointOfServices,
  PointOfService,
  ProductAvailabilityInformation,
  SubsidiaryInformation,
  DisplayInformation,
} from 'graphql-types/generated/types-generated';
import { ApolloQueryResult, QueryLazyOptions } from '@apollo/client';
import { Coords } from 'google-map-react';
import { PinTypes } from 'types/types';

export interface SubsidiariesListItemDetails {
  name?: string | null;
  status?: PinTypes;
  isDeliveryTimeShown?: boolean;
  isDisabled?: boolean;
  address?: string | null;
  distance?: string;
  deliveryTime?: number | null;
  deliveryTimeText?: string | null;
  code?: string | null;
  locationType?: string | null;
  productLocationInStore?: DisplayInformation['location'];
  isProductLocationInStoreShown?: boolean;
  isStoreDetailsButtonShown?: boolean;
  isSelected?: boolean;
  pointOfService?: PointOfService | null;
}

export enum ProductAvailabilityStatus {
  AVAILABLE = 'available',
  NOT_AVAILABLE = 'notAvailable',
  SOON_AVAILABLE = 'soonAvailable',
  LATER_AVAILABLE = 'laterAvailable',
}

export interface SubsidiariesListPerStatus {
  [ProductAvailabilityStatus.AVAILABLE]: SubsidiariesListItemDetails[];
  [ProductAvailabilityStatus.NOT_AVAILABLE]: SubsidiariesListItemDetails[];
}

export interface SelectedPointOfServiceLocation {
  zipCode: string;
  zipLabel: string;
  scrollIntoView: boolean;
  code: string;
}

export interface UIConfiguration {
  defaultMapZoom: number;
  notFoundZoom: number;
  coordinationDefaultValue: number;
}

export interface StateSetters {
  setIsServiceInfoModalOpen: (isServiceInfoModalOpen: boolean) => void;
  setIsStoreDetailsModalOpen: (isStoreDetailsModalOpen: boolean) => void;
  setInputZipCode: (inputZipCode: string) => void;
  setInputErrorCode: (inputErrorCode: string | null) => void;
  setSelectedPickupStationLocation: (selectedPickupStation: SelectedPointOfServiceLocation) => void;
  setSelectedSubsidiaryLocation: (selectedSubsidiary: SelectedPointOfServiceLocation) => void;
  setSelectedPointOfServiceLocation: (data: SelectedPointOfServiceLocation) => void;
  setProductAvailability: (productAvailability: ProductAvailabilityInformation) => void;
  setProductAvailabilityPickupStations: (
    productAvailabilityPickupStations: PointOfServices,
  ) => void;
}

export interface ModalState {
  state: {
    isServiceInfoModalOpen: boolean;
    isStoreDetailsModalOpen: boolean;
    inputZipCode: string;
    inputErrorCode: string | null;
    selectedPickupStationLocation: SelectedPointOfServiceLocation;
    selectedSubsidiaryLocation: SelectedPointOfServiceLocation;
    selectedPointOfServiceLocation: SelectedPointOfServiceLocation;
    productAvailability: ProductAvailabilityInformation;
    productAvailabilityPickupStations: PointOfServices;
  };
  stateSetters: StateSetters;
}

export interface SearchPickupStationsQueryVariables {
  postalCode: string;
  variables?: any;
}

export interface GetProductAvailabilityQueryVariables {
  productCode: string;
  zipCode: string;
  qty: string | null;
}

export interface SelectedMapData {
  geoPoint: Coords;
  zoom: number;
}

export interface ServiceData {
  i18n: { [key: string]: string };
  uiConfiguration: UIConfiguration;
  modalState: ModalState;
  selectedCustomShippingType: ModalCustomShippingTypes;
  listItems?: SubsidiariesListPerStatus;
  isFetchingAvailabilityResults: boolean;
  initiallySelectedMapData: SelectedMapData;
}

export type FetchAvailabilityResult =
  | Promise<ApolloQueryResult<{ getProductAvailability?: ProductAvailabilityInformation }>>
  | Promise<ApolloQueryResult<{ searchPickupStations?: PointOfServices }>>;

export interface ServiceDataInitializer extends ServiceData {
  fetchAvailabilityResult: (
    argumentsPickupStations: QueryLazyOptions<SearchPickupStationsQueryVariables>,
    argumentsInternalServices: QueryLazyOptions<GetProductAvailabilityQueryVariables>,
  ) => FetchAvailabilityResult;
}

export const enum ModalCustomShippingTypes {
  SELF_SERVICE = 'SELF_SERVICE',
  RESERVATION = 'RESERVATION',
  POSTAGE_PICKUP = 'POSTAGE_PICKUP',
}

export interface ServiceInformationStep {
  /* cms item unique code */
  code: string;
  /* description step id */
  id: string;
  /* step description - message to display */
  content: string;
}

export interface ServiceInformationResult {
  /* data structure containing an array with service description steps */
  data: {
    main: ServiceInformationStep[];
  };
}

export interface ServiceInformationData {
  getNavigationNodes: ServiceInformationResult;
}

export enum LocationStorageSource {
  LOCATION = 'location',
  LOCATION_FOR_CART = 'locationForCart',
}

export interface SubsidiaryModalProps {
  /** the result of the product availability query */
  initialProductAvailability: ProductAvailabilityInformation;
  /** the result of the product availability pickup stations query */
  initialAvailabilityPickupStations?: PointOfServices;
  /** the initially selected subsidiary */
  initialSelectedSubsidiary: SubsidiaryInformation;
  /** the initially selected pickup station */
  initialSelectedPickupStation?: PointOfService;
  /** a callback passed to the modal closing button */
  onModalClose: () => void;
  /** a callback passed to the modal if an option got confirmed */
  onModalConfirmation: (
    updatedAvailabilityResult: ProductAvailabilityInformation,
    updatedAvailabilityPickupStations?: PointOfServices,
  ) => void;
  /** the custom shipping type - on this depends the content of the modal  */
  selectedCustomShippingType: ModalCustomShippingTypes;
  /** the product code */
  productCode?: string;
  /** the product amount */
  productAmount?: string;
  /** where we are storing the selected subsidiaries for different cases */
  locationStorageSource?: LocationStorageSource;
  /** a flag, which is setting if the not available subsidiaries in the list are not selectable */
  withDisabledNotAvailableSubsidiaries?: boolean;
}
